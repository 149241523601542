<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="text-center">
              <h5 class="card-title"> {{ $t('externalTraining.course_evaluation') }} {{ $t('globalTrans.details') }}</h5>
            </div>
          </template>
          <template v-slot:headerAction>
            <router-link class="btn btn_add_new" :to="`training-course-evaluation`">
              {{ $t('externalTraining.course_evaluation') }} {{ $t('globalTrans.list') }}
            </router-link>
          </template>
          <template v-slot:body>
            <div class="p-5">
              <b-row>
                <b-col>
                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                    <div class="titleDiv">
                    </div>
                  </list-report-head>
                </b-col>
              </b-row>
              <row>
                <b-col sm="12" class="text-center"><u>{{ $t('externalTraining.common_assessment_question_paper') }}</u></b-col>
              </row>
              <b-row class="mt-5">
                <b-col sm="12"><h5>{{ $t('externalTraining.course_title') + ' : ' +  ($i18n.locale === 'bn' ? circularData.training_title_bn : circularData.training_title_en) }}</h5></b-col>
                <b-col sm="12"><h5>{{ $t('externalTraining.course_duration') + ' : ' + ($i18n.locale === 'bn' ? date_bn : date) }}</h5></b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple borderless class="tg mt-3" v-if="detailsData" hover small caption-top>
                    <tbody>
                      <tr>
                        <td style=""  class="text-left" ><h5>{{ $t('externalTraining.question_paper_head') }}</h5></td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '১। ' : '1। ' }} {{ $t('externalTraining.question_1')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + ($i18n.locale === 'en' ? detailsData.question_1 : detailsData.question_1_bn) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '২। ' : '2। ' }}  {{ $t('externalTraining.question_2')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + ($i18n.locale === 'en' ? detailsData.question_2 : detailsData.question_2_bn) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '৩। ' : '3। ' }} {{ $t('externalTraining.question_3')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">
                          {{ $t('externalTraining.answer') }}<div v-html="detailsData.question_3"></div>
                        </td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '৪। ' : '4। ' }} {{ $t('externalTraining.question_4')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">
                          {{ $t('externalTraining.answer') }}<div v-html="detailsData.question_4"></div>
                        </td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '৫। ' : '5। ' }}  {{ $t('externalTraining.question_5')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">
                          {{ $t('externalTraining.answer') }}<div v-html="detailsData.question_5"></div>
                        </td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '৬। ' : '6। ' }}  {{ $t('externalTraining.question_6')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + ($i18n.locale === 'en' ? detailsData.question_6 : detailsData.question_6_bn) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '৭। ' : '7। ' }}  {{ $t('externalTraining.question_7')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + ($i18n.locale === 'en' ? detailsData.question_7 : detailsData.question_7_bn) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '৮। ' : '8। ' }}  {{ $t('externalTraining.question_8')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + getAnswer8Text(detailsData.question_8) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '৯। ' : '9। ' }} {{ $t('externalTraining.question_9')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + getAnswer9Text(detailsData.question_9) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '১০। ' : '10। ' }}  {{ $t('externalTraining.question_10')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + ($i18n.locale === 'en' ? detailsData.question_10 : detailsData.question_10_bn) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '১১। ' : '11। ' }} {{ $t('externalTraining.question_11')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + (detailsData.question_11) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '১২। ' : '12। ' }}  {{ $t('externalTraining.question_12')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + (detailsData.question_12) }}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '১৩। ' : '13। ' }} {{ $t('externalTraining.question_13')}}</td>
                      </tr>
                      <tr>
                        <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + (detailsData.question_13) }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <tr>
                    <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '১৪। ' : '14। ' }}  {{ $t('externalTraining.question_14') }}</td>
                  </tr>
                  <tr>
                    <td style=""  class="text-left">
                      <table class="table" style="width:100%" border="1">
                        <thead class="thead">
                          <tr>
                            <th class="text-center" style="width:10%; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{$t('globalTrans.sl_no')}}</th>
                            <th class="text-center" style="width:20%; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{$t('externalTraining.question_14_1')}}</th>
                            <th class="text-center" style="width:14%; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{$t('externalTraining.question_14_2')}}</th>
                            <th class="text-center" style="width:14%; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{$t('externalTraining.question_14_3')}}</th>
                            <th class="text-center" style="width:14%; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{$t('externalTraining.question_14_4')}}</th>
                            <th class="text-center" style="width:14%; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{$t('externalTraining.question_14_5')}}</th>
                            <th class="text-center" style="width:14%; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{$t('externalTraining.question_14_6')}}</th>
                          </tr>
                        </thead>
                        <tr v-for="(data, index) in detailsData.question_14" :key="index">
                          <td style="; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{ $n(index + 1) }}</td>
                          <td style="; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">{{ $i18n.locale === 'bn' ? data.name_bn : data.name }}</td>
                          <td style="; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;" class="text-center">
                            <slot v-if="data.value === 100"><i class="text-success fa fontawesome">&#xf00c;</i></slot>
                          </td>
                          <td style="; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;" class="text-center">
                            <slot v-if="data.value === 90"><i class="text-success fa fontawesome">&#xf00c;</i></slot>
                          </td>
                          <td style="; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;" class="text-center">
                            <slot v-if="data.value === 80"><i class="text-success fa fontawesome">&#xf00c;</i></slot>
                          </td>
                          <td style="; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;" class="text-center">
                            <slot v-if="data.value === 70"><i class="text-success fa fontawesome">&#xf00c;</i></slot>
                          </td>
                          <td style="; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;" class="text-center">
                            <slot v-if="data.value === 60"><i class="text-success fa fontawesome">&#xf00c;</i></slot>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <tr>
                    <td style=""  class="text-left">{{ $i18n.locale === 'bn' ? '১৫। ' : '15। ' }}  {{ $t('externalTraining.question_15')}}</td>
                  </tr>
                  <tr>
                    <td style=""  class="text-left">{{ $t('externalTraining.answer') + ' ' + (detailsData.question_15) }}</td>
                  </tr>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
        <!-- <pre>{{allData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularData, traineeCourseEvaluationDetails } from '../../api/routes'
import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
// import ExportPdf from './export_pdf_details'

export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    this.getCircularData()
    this.getDetailsData()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      date: '',
      date_bn: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      detailsData: {},
      circularData: {}
    }
  },
  computed: {
    question1Option () {
      const question1Option = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Enough' : 'পর্যাপ্ত', text_en: 'Enough', text_bn: 'পর্যাপ্ত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Roughly' : 'মোটামুটি', text_en: 'Roughly', text_bn: 'মোটামুটি' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Inadequate' : 'অপর্যাপ্ত', text_en: 'Inadequate', text_bn: 'অপর্যাপ্ত' }
      ]
      return question1Option
    },
    question2Option () {
      const question2Option = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Very Long' : 'খুবই দীর্ঘ', text_en: 'Very Long', text_bn: 'খুবই দীর্ঘ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Enough' : 'পর্যাপ্ত', text_en: 'Enough', text_bn: 'পর্যাপ্ত' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Roughly' : 'মোটামুটি', text_en: 'Roughly', text_bn: 'মোটামুটি' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Very Short' : 'খুবই সংক্ষিপ্ত', text_en: 'Very Short', text_bn: 'খুবই সংক্ষিপ্ত' }
      ]
      return question2Option
    },
    question6Option () {
      const question6Option = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Very Good' : 'খুবই ভাল', text_en: 'Very Good', text_bn: 'খুবই ভাল' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Good' : 'ভাল', text_en: 'Good', text_bn: 'ভাল' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Roughly' : 'মোটামুটি', text_en: 'Roughly', text_bn: 'মোটামুটি' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Not Good' : 'ভাল নয়', text_en: 'Not Good', text_bn: 'ভাল নয়' }
      ]
      return question6Option
    },
    question7Option () {
      const question7Option = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Very Relevant' : 'খুবই প্রাসংগিক', text_en: 'Very Relevant', text_bn: 'খুবই প্রাসংগিক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Fairly Relevant' : 'মোটামুটি প্রাসংগিক', text_en: 'Fairly Relevant', text_bn: 'মোটামুটি প্রাসংগিক' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Relevant in some cases' : 'কোন কোন ক্ষেত্রে প্রাসংগিক', text_en: 'Relevant in some cases', text_bn: 'কোন কোন ক্ষেত্রে প্রাসংগিক' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Not relevant at all' : 'একেবারেই প্রাসংগিক না', text_en: 'Not relevant at all', text_bn: 'একেবারেই প্রাসংগিক না' }
      ]
      return question7Option
    },
    question8Option () {
      const question8Option = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Being aware of knowledge and information' : 'জ্ঞান ও তথ্য সম্পর্কে অবগত হওয়া', text_en: 'Being aware of knowledge and information', text_bn: 'জ্ঞান ও তথ্য সম্পর্কে অবগত হওয়া' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Know the special abilities and methods to work' : 'কাজ করার জন্য বিশেষ ক্ষমতা ও পদ্ধতি জানা', text_en: 'Know the special abilities and methods to work', text_bn: 'কাজ করার জন্য বিশেষ ক্ষমতা ও পদ্ধতি জানা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'To acquire the ability to function properly' : 'সুষ্ঠুভাবে কাজ করার ক্ষমতা অর্জন করা', text_en: 'To acquire the ability to function properly', text_bn: 'সুষ্ঠুভাবে কাজ করার ক্ষমতা অর্জন করা' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Confidence levels have increased' : 'কনফিডেন্সের মাত্রা বেড়েছে', text_en: 'Confidence levels have increased', text_bn: 'কনফিডেন্সের মাত্রা বেড়েছে' }
      ]
      return question8Option
    },
    question9Option () {
      const question9Option = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Classroom Lectures' : 'ক্লাশরুম বক্তৃতা', text_en: 'Classroom Lectures', text_bn: 'ক্লাশরুম বক্তৃতা' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Discussion and practice' : 'আলোচনা ও অনুশীলন', text_en: 'Discussion and practice', text_bn: 'আলোচনা ও অনুশীলন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'group discussion' : 'দলগত আলোচনা', text_en: 'group discussion', text_bn: 'দলগত আলোচনা' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Group problem solving' : 'দলগত সমস্যা সমাধান', text_en: 'Group problem solving', text_bn: 'দলগত সমস্যা সমাধান' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Field visit' : 'মাঠ পরিদর্শন', text_en: 'Field visit', text_bn: 'মাঠ পরিদর্শন' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Film show' : 'ফিল্ম শো', text_en: 'Film show', text_bn: 'ফিল্ম শো' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'Other' : 'অন্যান্য', text_en: 'Other', text_bn: 'অন্যান্য' }
      ]
      return question9Option
    },
    question10Option () {
      const question10Option = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Very Satisfactory' : 'খুবই সন্তোষজনক', text_en: 'Very Satisfactory', text_bn: 'খুবই সন্তোষজনক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Satisfactory' : 'সন্তোষজনক', text_en: 'Satisfactory', text_bn: 'সন্তোষজনক' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Not Satisfactory' : 'সন্তোষজনক নয়', text_en: 'Not Satisfactory', text_bn: 'সন্তোষজনক নয়' }
      ]
      return question10Option
    }
  },
  methods: {
      getDetailsData () {
        this.isLoading = true
        const params = {
          circular_memo_no: this.$route.query.circularMemoNo
        }
        RestApi.getData(trainingElearningServiceBaseUrl, traineeCourseEvaluationDetails, params).then(response => {
          if (response.success) {
            this.detailsData = this.getCustomDataList(response.data)
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        })
      },
      getCustomDataList (data) {
        const question1Obj = this.question1Option.find(doc => doc.value === parseInt(data.question_1))
        const question1Data = {}
        if (typeof question1Obj !== 'undefined') {
          question1Data.question_1 = question1Obj.text_en
          question1Data.question_1_bn = question1Obj.text_bn
        } else {
          question1Data.question_1 = ''
          question1Data.question_1_bn = ''
        }
        const question2Obj = this.question2Option.find(doc => doc.value === parseInt(data.question_2))
        const question2Data = {}
        if (typeof question2Obj !== 'undefined') {
          question2Data.question_2 = question2Obj.text_en
          question2Data.question_2_bn = question2Obj.text_bn
        } else {
          question2Data.question_2 = ''
          question2Data.question_2_bn = ''
        }
        const question6Obj = this.question6Option.find(doc => doc.value === parseInt(data.question_6))
        const question6Data = {}
        if (typeof question6Obj !== 'undefined') {
          question6Data.question_6 = question6Obj.text_en
          question6Data.question_6_bn = question6Obj.text_bn
        } else {
          question6Data.question_6 = ''
          question6Data.question_6_bn = ''
        }
        const question7Obj = this.question7Option.find(doc => doc.value === parseInt(data.question_7))
        const question7Data = {}
        if (typeof question7Obj !== 'undefined') {
          question7Data.question_7 = question7Obj.text_en
          question7Data.question_7_bn = question7Obj.text_bn
        } else {
          question7Data.question_7 = ''
          question7Data.question_7_bn = ''
        }
        const question10Obj = this.question10Option.find(doc => doc.value === parseInt(data.question_10))
        const question10Data = {}
        if (typeof question10Obj !== 'undefined') {
          question10Data.question_10 = question10Obj.text_en
          question10Data.question_10_bn = question10Obj.text_bn
        } else {
          question10Data.question_10 = ''
          question10Data.question_10_bn = ''
        }
        return Object.assign({}, data, question1Data, question2Data, question6Data, question7Data, question10Data)
      },
      getCircularData () {
        this.isLoading = true
        const params = {
          circular_memo_no: this.$route.query.circularMemoNo
        }
        RestApi.getData(trainingElearningServiceBaseUrl, circularData, params).then(response => {
          if (response.success) {
            this.circularData = response.data
            const startDate = response.data.training_start_date.split('-')
            const endDate = response.data.training_end_date.split('-')
            let startMonthId = ''
            let endMonthId = ''
            startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
            endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
              this.date = startDate[2] + ' ' + startMonthId.text_en + ' ' + '-' + ' ' + endDate[2] + ' ' + endMonthId.text_en + ' ' + startDate[0]
              this.date_bn = this.convertToBanglaNumbers(startDate[2]) + ' ' + startMonthId.text_bn + ' ' + '-' + ' ' + this.convertToBanglaNumbers(endDate[2]) + ' ' + endMonthId.text_bn + ' ' + this.convertToBanglaNumbers(startDate[0], { useGrouping: false })
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        })
      },
      convertToBanglaNumbers (input) {
        function toBanglaNumber (match) {
          const numberMap = {
            0: '০',
            1: '১',
            2: '২',
            3: '৩',
            4: '৪',
            5: '৫',
            6: '৬',
            7: '৭',
            8: '৮',
            9: '৯'
          }
          return match.split('').map(char => numberMap[char] || char).join('')
        }
        return input.replace(/[0-9]/g, toBanglaNumber)
      },
      getAnswer8Text (datas) {
        const lesson = []
        for (const key in datas) {
          const item = datas[key]
          const question8Obj = this.question8Option.find(doc => doc.value === parseInt(item))
          if (typeof question8Obj !== 'undefined') {
            if (this.$i18n.locale === 'bn') {
              lesson.push(question8Obj.text_bn)
            } else {
              lesson.push(question8Obj.text_en)
            }
          } else {
            lesson.push('')
          }
        }
        return lesson.join(', ')
      },
      getAnswer9Text (datas) {
        const lesson = []
        for (const key in datas) {
          const item = datas[key]
          const question8Obj = this.question9Option.find(doc => doc.value === parseInt(item))
          if (typeof question8Obj !== 'undefined') {
            if (this.$i18n.locale === 'bn') {
              lesson.push(question8Obj.text_bn)
            } else {
              lesson.push(question8Obj.text_en)
            }
          } else {
            lesson.push('')
          }
        }
        return lesson.join(', ')
      }
  }
}
</script>
